<!--
 * @Module  列表页
 -->

<template>
  <div>
    <!-- PC端展示内容 -->
    <div v-if="isPc" class="list">
      <template>
        <!-- <img v-if="categoryImg" class="list_banner" :src="handleUrl(categoryImg)" alt="" />
        <img v-else class="list_banner" src="~@/assets/images/pc/banner_subject.png" alt="" /> -->
      </template>

      <div class="list_main">
        <div class="list_main-pc">
          <!-- 左侧菜单栏目 -->
          <LeftMenu style="margin-top: 100px;" :categoryTitle="categoryTitle" :subMenuList="subMenuList" @handleLeftMenu="handleCategoryList"></LeftMenu>

          <!-- 列表 -->
          <div class="list_main-pc-list">
            <div class="location" style="margin-top: 40px;">
              <div class="location_title">{{ subTitle }}</div>
              <div class="location_link">
                <navber :data="navberList" @navbarChange="navbarChange"></navber>
              </div>
            </div>

            <components v-show="id !== ''" :is="cutTab" :id="id" />
          </div>
        </div>
      </div>
    </div>

    <!-- wap端展示内容 -->
    <div v-else class="list-wap">
       <div class="list_main">
        <div class="list_main-wrap">
          <!-- 列表 -->
          <div class="list_main-wrap-list">
            <div class="location">
              <div class="location_title">
                {{ subTitle }}
              </div>
            </div>
            <components v-show="id !== ''" :is="cutTab" :id="id" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!--JavaScript-->
<script>
import base from '@/utils/base'
import { mapState, mapGetters } from 'vuex'
import LeftMenu from '@/components/left-menu/index'
import Navber from '@/components/navber/navber'
import list from './diy/list/list' // 列表页 通常页
import { getTreeMenu, convertImgUrl } from '@/utils/index'
export default {
  name: 'MachineList',
  mixins: [base],
  data() {
    return {
      cutTab: '',
      categoryImg: '',
      navberList: [], // 导航数据
      subMenuList: [], // 左侧子菜单数据
      categoryTitle: '', // 子菜单标题
      subTitle: '', // 子内容标题
      id: '' // 列表页的父级的id 用来请求数据
    }
  },
  created() {
    // 梳理整合数据
    this.init()
  },
  methods: {
    async init() {
      if (!this.categoryList) {
        try {
          const { errCode, data } = await this.api.post(
            '/webclient/getSiteCategory',
            {
              siteId: this.$siteId
            }
          )
          if (!errCode) {
            // 排序树结构数据
            data.sort(function (a, b) {
              return a.categorySort - b.categorySort
            })

            // 菜单栏目（isMenu为 1显示）
            const filterData = (data || []).filter((item) => item.isMenu)
            this.$store.commit('setCategoryList', filterData)
            this.combination(this.$route)
          }
        } catch (error) {
          console.log('getSiteCategory：异常')
        }
      } else {
        this.combination(this.$route)
      }
    },
    /**
     * 梳理整合数据
     * @param {Object} route 路由对象
     */
    combination(route) {
      let {
        query: { categoryId, id }
      } = route
      categoryId = parseInt(categoryId) ? parseInt(categoryId) : ''
      id = parseInt(id) ? parseInt(id) : ''

      this.id = id // 列表页的父级的id 用来请求数据

      if (!categoryId || categoryId === '') {
        // 代表为一级菜单直接点击获得数据列表 (无子菜单的一级菜单)

        // 获取本一级菜单的详情数据
        const firstMenu = this.categoryList
          .filter((item) => item.id === id)
          .pop()

        if (!firstMenu) {
          this.naviGate('index', { t: new Date().getTime() }) // 首页id:0(特殊)
          return
        }

        // 根据配置的栏目下属列表页url 展示不同组件
        this.cutTab =
          firstMenu.categoryListUrl && firstMenu.categoryListUrl !== ''
            ? firstMenu.categoryListUrl
            : 'list'

        this.navberList = [
          {
            level: 1,
            categoryId: firstMenu.categoryId,
            id: firstMenu.id,
            categoryTitle: firstMenu.categoryTitle,
            isLeaf: firstMenu.isLeaf
          }
        ] // 赋值导航菜单
        this.subMenuList = [] // 赋值左侧子菜单数据
        this.categoryImg = firstMenu.categoryImg
        this.categoryTitle = firstMenu.categoryTitle // 赋值子菜单标题
        this.subTitle = firstMenu.categoryTitle // 赋值子内容标题
      } else {
        // 代表为点击二级或三级菜单获得数据列表 (有子菜单的一级菜单 点击等同二级菜单第一个菜单点击)

        // 通过子节点id获取该树分支中的所有父节点数据
        let treeMenu = getTreeMenu(this.menuList, id) || []

        // 取第一层的父结构(包含所有子节点的树结构)
        let manyMenu = treeMenu
          .filter((item) => {
            return !item.categoryId
          })
          .pop()

        // 获取本级菜单的详情数据
        const secondMenu =
          this.categoryList.filter((item) => item.id === id).pop() || []

        if (secondMenu.length === 0) {
          this.naviGate('index', { t: new Date().getTime() }) // 首页id:0(特殊)
          return
        }

        // 根据配置的栏目下属列表页url 展示不同组件
        this.cutTab =
          secondMenu.categoryListUrl && secondMenu.categoryListUrl !== ''
            ? secondMenu.categoryListUrl
            : 'list'

        this.navberList = [
          {
            level: 1,
            categoryId: '',
            id: manyMenu.id,
            categoryTitle: manyMenu.categoryTitle,
            isLeaf: manyMenu.isLeaf
          },
          {
            level: 2,
            categoryId: secondMenu.categoryId,
            id: secondMenu.id,
            categoryTitle: secondMenu.categoryTitle,
            isLeaf: secondMenu.isLeaf
          }
        ] // 赋值导航菜单

        manyMenu.child.forEach((item, index) => {
          item.checked = false
          item.select = false
          if (item.id === secondMenu.id) {
            item.checked = true
            item.select = true
          } else {
            item.checked = false
          }

          if (item.child) {
            item.child.forEach((obj, i) => {
              if (obj.id === secondMenu.id) {
                obj.checked = true

                // 此项目额外选中父栏目
                item.checked = true
                item.select = true
              } else {
                obj.checked = false
              }
            })
          }
        })

        this.subMenuList = manyMenu.child // 赋值左侧子菜单数据
        this.categoryImg = manyMenu.categoryImg
        this.categoryTitle = manyMenu.categoryTitle // 赋值子菜单标题
        this.subTitle = secondMenu.categoryTitle // 赋值子内容标题
      }
    },
    /**
     * @description: 拼接图片地址
     * @param {string} url 图片地址
     */
    handleUrl(url) {
      return convertImgUrl(url)
    },
    loadStyle() {
      if (!this.isPc) {
        require('@/assets/module/wap/list.scss')
      } else {
        require('@/assets/module/pc/list.scss')
      }
    }
  },
  computed: {
    ...mapState(['categoryList', 'isPc']),
    ...mapGetters(['menuList'])
  },
  watch: {
    isPc: {
      handler: 'loadStyle',
      immediate: true
    },
    $route(to, from) {
      // 梳理整合数据
      this.combination(to)
    }
  },
  components: { Navber, LeftMenu, list }
}
</script>

<style>
.navber-wap {
  border : none !important;
}
</style>
