<!--
 * @Module  列表页 通常页
 -->

<template>
  <!-- 列表 -->
  <div>
    <!-- PC端展示内容 -->
    <div v-if="isPc" class="list-main">
      <div class="list_news">
        <div v-if="mainLists.length">
          <div
            class="list_news-item"
            v-for="(item, index) in mainLists"
            :key="index"
            @click="handleRowDetail(item)"
          >
            <div class="list_dot"></div>

            <div class="item_info">
              <div
                class="info_title"
                style="display: flex; align-items: center"
              >
                <img
                  v-if="item.contentSort < 0"
                  style="width: 44px; height: 20px; margin-right: 8px"
                  src="@/assets/images/common/icon_hot.png"
                  alt=""
                />
                <span
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                >
                  {{ item.contentTitle }}
                </span>
              </div>
              <!-- <div class="info_intro">
                {{ item.contentDescription }}
              </div> -->
            </div>

            <div class="info_date" style="margin-left: 20px">
              {{ getDateTime(item.contentTime) }}
            </div>
          </div>
        </div>
        <div v-else class="no-data">暂无数据</div>
      </div>

      <div class="list_page" v-if="mainLists.length && total > 10">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          :current-page="currentPage"
          :pager-count="5"
          :page-size="isPc ? 10 : 5"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>

    <!-- wap端展示内容 -->
    <div v-else class="list-main-wap">
      <div class="list_news">
        <div v-if="mainLists.length">
          <div
            class="list_news-item"
            v-for="(item, index) in mainLists"
            :key="index"
            @click="handleRowDetail(item)"
          >
            <div style="display: flex; align-items: center">
              <div class="list_dot"></div>

              <div class="item_info">
                <div
                  class="info_title"
                  style="display: flex; align-items: center"
                >
                  <img
                    v-if="item.contentSort < 0"
                    style="width: 44px; height: 20px; margin-right: 8px"
                    src="@/assets/images/common/icon_hot.png"
                    alt=""
                  />
                  <span
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    {{ item.contentTitle }}
                  </span>
                </div>
                <!-- <div class="info_intro">
                {{ item.contentDescription }}
              </div> -->
              </div>
            </div>
            <div class="info_date">
              {{ getDateTime(item.contentTime) }}
            </div>
          </div>
        </div>
        <div v-else class="no-data">暂无数据</div>
      </div>

      <div class="list_page" v-if="mainLists.length && total > 10">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :current-page="currentPage"
          :pager-count="3"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<!--JavaScript-->
<script>
import { mapState } from 'vuex'
import { dateObj } from '@/utils/index'
import Pager from '@/components/pager/index'
export default {
  name: 'MachineList',
  data() {
    return {
      that: this,
      mainLists: [],
      pageSize: 10,
      currentPage: 1,
      total: 0,
      isShowPagination: true // 是否显示分页
    }
  },
  props: {
    id: {
      type: Number
    } // 列表页的父级的id 用来请求数据
  },
  created() {
    this.$nextTick(() => {
      // 判断页码缓存
      let pageNumber = sessionStorage.getItem('pageNumber')
      if (pageNumber) {
        pageNumber = JSON.parse(pageNumber)
        if (pageNumber.id == this.id) {
          this.currentPage = Number(pageNumber.currentPage)

          sessionStorage.setItem('pageNumber', '{}')

          // 用于更新分页器
          this.isShowPagination = false
        } else {
          this.currentPage = 1
        }
      }
      // 获取栏目下的信息列表
      this.getListData()
    })
  },
  methods: {
    /**
     * 获取栏目下的信息列表
     */
    async getListData() {
      try {
        if (!this.id) {
          console.log('id is null')
          return
        }
        const { errCode, data } = await this.api.post(
          '/webclient/getContentList',
          {
            categoryId: this.id,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
            contentDisplay: false,
            orders: [{ column: 'contentTime', asc: false }]
          }
        )
        if (!errCode) {
          const { total, records } = data
          this.mainLists = (records || []).filter(
            (item) => item.contentDisplay !== 'hidden'
          )
          this.total = total
          if (this.$route.query.categoryId == 45 && records.length > 0) {
            this.handleRowDetail(records[0])
          }
          // 用于更新分页器
          this.isShowPagination = true
        } else {
          this.mainLists = []
          this.total = 0
        }
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * 分页页数变化
     */
    handleCurrentChange(val) {
      this.currentPage = val
      this.getListData()
    },
    /**
     * 子内容列表点击
     */
    handleRowDetail(row) {
      const { contentUrl, id = null } = row

      if (contentUrl) {
        // 外链
        window.open(contentUrl)
      } else {
        const data = {
          id: this.id,
          currentPage: this.currentPage
        }
        sessionStorage.setItem('pageNumber', JSON.stringify(data))

        // 内链
        this.naviGate('detail', { t: new Date().getTime(), contentId: id }, false, 'replace')
      }
    },
    /**
     * @description: 时间格式转换 YYYY.MM.DD
     * @param {number} time 时间戳
     */
    getDateTime(time) {
      let date = dateObj(time)

      return date.year + '-' + date.month + '-' + date.day
    }
  },
  watch: {
    id(val) {
      this.currentPage = 1

      // 获取栏目下的信息列表
      this.getListData()
    }
  },
  filters: {},
  computed: {
    ...mapState(['isPc'])
  },
  components: { Pager }
}
</script>
<style lang="scss" scoped>
.list-main {
  .list_news {
    flex: 1 1 auto;
    margin-bottom: 40px;

    &-item {
      display: flex;
      align-items: center;
      margin-top: 28px;
      cursor: pointer;

      &:hover {
        .list_dot {
          background: #409eff;
        }

        .item_info {
          .info_title {
            color: #409eff;
          }
        }
      }

      .list_dot {
        width: 6px;
        height: 6px;
        background: #ececec;
        margin-right: 12px;
        border-radius: 100%;
      }

      .item_info {
        flex: 1;
        width: 0;

        .info_title {
          width: 100%;
          color: #333333;
          font-size: 16px;
          /* font-weight: bold; */
          @include ellipsis;
        }

        .info_intro {
          width: 100%;
          font-size: 14px;
          color: #777777;
          line-height: 20px;
          @include ellipsis;
        }
      }

      .info_date {
        font-size: 14px;
        color: #999999;
      }
    }
  }

  .list_page {
    text-align: center;
    padding-bottom: 72px;
  }
}

.list-main-wap {
  width: 100%;
  overflow-x: hidden;
  .list_news {
    flex: 1 1 auto;
    margin-bottom: 40px;
    &-item {
      padding: 0.18rem 0 0.15rem;
      border-bottom: 1px solid #E2E2E2;
      .list_dot {
        width: 0.06rem;
        height: 0.06rem;
        background: #0E59AE;
        margin-right: 0.1rem;
        border-radius: 100%;
      }

      .item_info {
        flex: 1;
        width: 0;

        .info_title {
          width: 100%;
          color: #333333;
          font-size: 0.16rem;
          font-weight: bold;
          font-family: PingFangSC-Medium, PingFang SC;
          @include ellipsis;
        }

        .info_intro {
          width: 100%;
          font-size: 14px;
          color: #777777;
          line-height: 20px;
          @include ellipsis;
        }
      }

      .info_date {
        font-size: 0.14rem;
        color: #999999;
        margin: 0.08rem 0 0 0.16rem;
      }
    }
  }

  .list_page {
    width: 100% !important;
    text-align: center;
    // height: 0.32rem;
    padding-bottom: 0.32rem;
  }
}
</style>
